import React from 'react'
import SubpageHeader from '../views/SubpageHeader'
import Action from '../views/Action'

const ActionPage = () => {
  return (
    <div>
      <SubpageHeader/>
      <Action/>
    </div>
  )
}

export default ActionPage