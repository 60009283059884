import React from 'react'

const SubpageHeader = () => {
  return (
    <div className="bg-gray-50 flex items-center">
    <section className="SubpageHeader w-full bg-cover bg-center py-32 my-0">
        <div className="container mx-auto text-center text-white">
            <h1 className="text-5xl font-medium mb-6">ASSOCIATION DES JEUNES EPANOUIS EN ACTION</h1>
            <p className="text-xl mb-12">Bienvenue sur le site web de l'Association pour la Justice et l'Égalité pour Tous (AJEA) !</p>
        </div>
    </section>
</div>
  )
}

export default SubpageHeader