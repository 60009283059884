import React from 'react'
import Care from '../views/Care'
import SubpageHeader from '../views/SubpageHeader'

const About = () => {
    return (
        <div>
            <SubpageHeader/>
            <Care/>
        </div>
    )
}

export default About
