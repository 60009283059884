// local imports
import { careTitle, careSubtitle,careItemText, careList } from "../data";
import imageFour from "../assets/ajea.jpg";
import FadeIn from "../components/FadeIn";

const Care = () => {
  return (
    <div>

      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="lg:pr-10">
            <h5 className="mb-4 text-4xl font-extrabold leading-none">
              {careTitle}
            </h5>
            <hr className="mb-5 border-gray-300" />

            <p className="mb-6 text-gray-900" > 
            <div dangerouslySetInnerHTML={{ __html: careItemText }} />
             
            </p>
          </div>

          <FadeIn delay={0.6} direction="right">
            <div>
              <img
                className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
                src={imageFour} alt="photo"
              />
            </div>
          </FadeIn>
        </div>
      </div>







    </div>
  );
};

export default Care;
