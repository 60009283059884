import React from 'react'
import Hero from '../views/Hero'
import Services from '../views/Services'
import Care from '../views/Care'
import Contact from '../views/Contact'
import Action from '../views/Action'

const Home = () => {
  return (
    <div>
        <Hero/>
        <Services/>
        <Care/>
        <Contact/>
        <Action/>
    </div>
  )
}

export default Home