// local imports
import shippingIcon from "./assets/free-shiping-icon.svg";
import paymentIcon from "./assets/quick-payment-icon.svg";
import supportIcon from "./assets/support-icon.svg";
import productOneIcon from "./assets/product-one.svg";
import productTwoIcon from "./assets/produc-two.svg";
import productThreeIcon from "./assets/product-three.svg";
import plantIcon from "./assets/plant-icon.svg";
import sunIcon from "./assets/sun-icon.svg";
import waterIcon from "./assets/water-icon.svg";
import santeImg from "./assets/sante.jpg";
import educImg from "./assets/5437683.jpg";
import energieImg from "./assets/energie.jpg";
import temperatureIcon from "./assets/temperature-icon.svg";

export const heroTitle = "ASSOCIATION DES JEUNES EPANOUIS EN ACTION";
export const heroSubtitle =
  "Bienvenue sur le site web de l'Association pour la Justice et l'Égalité pour Tous (AJEA) !";

export const services = [
  {
    title: "L'éducation",
    subtitle: "Aller dans les communautés pour des séances de sensibilisations au travers des causeries éducatives, projections cinématographiques, groupes de parole.",
    icon: shippingIcon,
    img: educImg
  },
  {
    title: "Santé",
    subtitle: "Apporter aux personnes vulnérables du soutien « kits de suivi, de prévention et de santé. Organiser des campagnes de vaccinations et vaciliter l'acces au soin; Apporter un ccompagnement psychologique et médicale des survivantes de VBG.",
    icon: paymentIcon,
    img: santeImg
  },
  {
    title: "L'énergie",
    subtitle: "Fournir des points d’adduction d’eau potable et de facilité l’accès aux énergies alternatives tel quel l'energie solaire et autre..",
    icon: supportIcon,
    img:energieImg
  },
];

export const productsTitle = "Best Selling Products";
export const productsBtnText = "See Colection";

export const MessageAction = `Votre don est crucial pour aider ceux qui en ont le plus besoin. Nous avons besoin de votre générosité pour fournir des kits de santé, des fournitures scolaires, des points d'eau potable et financer nos projets. Chaque contribution compte et fait une réelle différence. Faites un don sur notre site ou contactez notre équipe pour plus d'informations. Merci pour votre soutien.`

export const products = [
  {
    title: "Golden Pothos",
    price: "$65.99",
    img: productOneIcon,
  },
  {
    title: "Rubber Plant",
    price: "$75.00",
    img: productTwoIcon,
  },
  {
    title: "Cast Iron Plant",
    price: "$88.50",
    img: productThreeIcon,
  },
];

export const referenceTitle = "Interior Plants Reference";
export const referenceSubtitle = "make your home cozy with refreshing plants";

export const careTitle = "QUI SOMMES NOUS ?";
export const careSubtitle = "ASSOCIATION DES JEUNES EPANOUIE EN ACTION";
export const careItemText = "Nous sommes une association à but non lucratif dédiée à venir en aide aux personnes défavorisées et vulnérables de notre société. Notre mission est de lutter contre les inégalités et de promouvoir la justice sociale pour tous. <br/> <br/> Chez AJEA, nous croyons fermement que chaque individu mérite d'être traité avec dignité et respect, quelles que soient ses circonstances ou son origine. Nous nous engageons à offrir un soutien et une assistance aux personnes les plus démunies, afin de les aider à retrouver leur autonomie et à reconstruire leur vie.<br/><br/>En travaillant en étroite collaboration avec les partenaires locaux, les institutions publiques et les bénévoles engagés, nous sommes en mesure d'avoir un impact significatif sur la vie des personnes défavorisées. Grâce à notre réseau de soutien, nous créons un environnement propice à l'épanouissement personnel et à la réalisation de chacun."

export const careList = [
  {
    title: "A PROPOS",
    subtitle:"Nous sommes une association à but non lucratif dédiée à venir en aide aux personnes défavorisées et vulnérables de notre société. Notre mission est de lutter contre les inégalités et de promouvoir la justice sociale pour tous. <br/> <br/> Chez AJEA, nous croyons fermement que chaque individu mérite d'être traité avec dignité et respect, quelles que soient ses circonstances ou son origine. Nous nous engageons à offrir un soutien et une assistance aux personnes les plus démunies, afin de les aider à retrouver leur autonomie et à reconstruire leur vie.<br/><br/> Nos actions se concentrent sur différents domaines d'intervention, tels que l'accès à l'éducation, la santé, le logement et l'emploi. Nous organisons des programmes de formation professionnelle, des ateliers éducatifs, des campagnes de sensibilisation et bien d'autres initiatives visant à améliorer la qualité de vie de ceux qui en ont le plus besoin.",
    img: sunIcon,
  }
];

export const newsletter = "Newsletter";

export const footerLinksColumnOne = [
  {
    title: "Support",
    links: ["About Us", "Careers", "Blog"],
  },
  {
    title: "Useful Links",
    links: ["A propos", "Nos actions", "Notre mission"],
  },
];

export const footerLinksColumnTwo = [
  {
    title: "Our Menu",
    links: ["Best Product", "Categories"],
  },
  {
    title: "Addresse",
    links: [
      "2e rue akwa face golden center, Bafoussam, Cameroun",
      "contact@ajeacm.com",
    ],
  },
];
