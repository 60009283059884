import React from 'react'
import SubpageHeader from '../views/SubpageHeader'
import Contact from '../views/Contact'

const ContactPage = () => {
  return (
    <div>
      <SubpageHeader/>
      <Contact/>
    </div>
  )
}

export default ContactPage