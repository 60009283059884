import { BrowserRouter, Route, Routes } from "react-router-dom";
// local imports
import "./index.css"
import Home from "./page/Home";
import Layout from "./views/Layout";
import About from "./page/About";
import Action from "./views/Action";
import ActionPage from "./page/ActionPage";
import ContactPage from "./page/ContactPage";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route path="" element={<Home/>}/>
            <Route path="about" element={<About/>}/> 
            <Route path="action" element={<ActionPage/>}/>
            <Route path="contact" element={<ContactPage/>}/>

          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
