import React from 'react'
import Action1 from "../assets/action1.jpg";
import Action2 from "../assets/action2.jpg";
import Action3 from "../assets/action3.jpg";
import Action4 from "../assets/action4.jpg";
import Action5 from "../assets/fofani.jpg"
import Action6 from "../assets/noel.jpg"

const Action = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col w-full mb-6 lg:justify-between lg:flex-row md:mb-8">
        <div className="flex items-center mb-5 md:mb-6 group lg:max-w-xl">
          <a href="/" aria-label="Item" className="mr-3">
            <div className="flex items-center justify-center w-16 h-16 rounded-full bg-indigo-50">
              <svg
                className="w-12 h-12 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
          </a>
          <h2 className="font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl">
            <span className="inline-block mb-2">Nos actions</span>
            <div className="h-1 ml-auto duration-300 origin-left transform bg-deep-purple-accent-400 scale-x-30 group-hover:scale-x-100" />
          </h2>
        </div>
        <p className="w-full text-gray-700 lg:text-sm lg:max-w-md">
          "Nos actions se concentrent sur différents domaines d'intervention, tels que l'accès à l'éducation, la santé, le logement et l'emploi. Nous organisons des programmes de formation professionnelle, des ateliers éducatifs, des campagnes de sensibilisation et bien d'autres initiatives visant à améliorer la qualité de vie de ceux qui en ont le plus besoin."
        </p>
      </div>
      <div className="grid gap-8 row-gap-5 mb-8 lg:grid-cols-3 lg:row-gap-8">
        <div>
          <img
            className="object-cover w-full h-56 mb-6 rounded shadow-lg md:h-64 xl:h-80"
            src={Action1}
            alt=""
          />
          <p className="mb-2 text-xl font-bold leading-none sm:text-2xl">
            Renforcement des capacités
          </p>
          <p className="text-gray-700">
            Renforcement des capacités des acteurs de protection de la plate-forme ouest du 21 au 23 février 2023 par UNHCR
            En effet l'AJEA organisation membre du cluster protection Ouest à participer à 03 jours de renforcement de  capacités .
          </p>
        </div>
        <div>
          <img
            className="object-cover w-full h-56 mb-6 rounded shadow-lg md:h-64 xl:h-80"
            src={Action2}
            alt=""
          />
          <p className="mb-2 text-xl font-bold leading-none sm:text-2xl">
            𝐑𝐞́𝐮𝐧𝐢𝐨𝐧 𝐝𝐮 𝐜𝐥𝐮𝐬𝐭𝐞𝐫 𝐩𝐫𝐨𝐭𝐞𝐜𝐭𝐢𝐨𝐧 𝐚𝐯𝐞𝐜 𝐥𝐞𝐬 𝐎𝐒𝐂 𝐝𝐞 𝐁𝐚𝐟𝐨𝐮𝐬𝐬𝐚𝐦
          </p>
          <p className="text-gray-700">
            Le 17 février à Bafoussam s'est tenue une réunion avec les acteurs de la société civile faisant dans la protection. l'ONG AJEA.  Nous avons abordé la question des zones à fortes concentrations, la visite de l'UNHCR, et des besoins en renforcement de capacité de l'OSC dans le secteur de la protection.
          </p>
        </div>
        <div>
          <img
            className="object-cover w-full h-56 mb-6 rounded shadow-lg md:h-64 xl:h-80"
            src={Action3}
            alt=""
          />
          <p className="mb-2 text-xl font-bold leading-none sm:text-2xl">
          sensibilisation en milieu scolaire
          </p>
          <p className="text-gray-700">
            Conscient des enjeux, Nous avons fait une descente sur le terrainle lundi 6 janvier 2023 dans une école publique celle de LAFE 2 pour un échange avec les tout-petits afin de les faire comprendre l'importance du vivre ensemble de la cohésion sociale et de la non-violence.
          </p>
        </div>
        <div>
          <img
            className="object-cover w-full h-56 mb-6 rounded shadow-lg md:h-64 xl:h-80"
            src={Action4}
            alt=""
          />
          <p className="mb-2 text-xl font-bold leading-none sm:text-2xl">
          𝐒𝐎𝐔𝐓𝐈𝐄𝐍 𝐀𝐔𝐗 𝐍𝐄́𝐂𝐄𝐒𝐒𝐈𝐓𝐄𝐔𝐗 POUR 𝐑𝐄𝐍𝐓𝐑𝐄́𝐄 𝐒𝐂𝐎𝐋𝐀𝐈𝐑𝐄
          </p>
          <p className="text-gray-700">
          Dans leur élan de cœur pour soutenir la rentrée scolaire de 25 enfants nécessiteux, l'association AJEA a en date du  01 septembre 2023, rendu visite et offert des dons en denrées alimentaires dans plusieurs quartiers de Bafoussam tels que:
          </p>
        </div>
        <div>
          <img
            className="object-cover w-full h-56 mb-6 rounded shadow-lg md:h-64 xl:h-80"
            src={Action5}
            alt=""
          />
          <p className="mb-2 text-xl font-bold leading-none sm:text-2xl">
         LUTTE CONTRE LES VIOLENCES BASE SUR LE GENRE          </p>
          <p className="text-gray-700">
          La lutte contre les VBG est l’une des priorités de l’AJEA c’est dans cette optique qu’un séminaire a été organisé à l'école de formation hôtelière la Fofani Foriht afin de sensibilisé
          </p>
        </div>
        <div>
          <img
            className="object-cover w-full h-56 mb-6 rounded shadow-lg md:h-64 xl:h-80"
            src={Action6}
            alt=""
          />
          <p className="mb-2 text-xl font-bold leading-none sm:text-2xl">
          LE SAPIN DE NOEL POUR TOUS 
          </p>
          <p className="text-gray-700">
          À l’occasion de la dernière fête du sapin l’AJEA a contribué à sa manière pour mettre un sourire sur le visage des tout petits.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Action
