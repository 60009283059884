// local imports
import { services } from "../data";
import FadeIn from "../components/FadeIn";

const Services = () => {
  return (
    <section>

      <div>
        <div className="mt-3 text-left sm:mt-5">
          <div className="py-6 text-center">
            <p className="mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600">MOTRE MISSION</p>
          </div>
        </div>
      </div>


      <div className="relative px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="relative mx-auto max-w-7xl">
          <div className="grid max-w-lg gap-5 mx-auto lg:grid-cols-3 lg:max-w-none">
            {services.map((service, i) => (
              <FadeIn key={i} delay={0.2} direction="down">
                <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                  <div className="flex-shrink-0">
                    <img className="object-cover w-full h-48" src={service.img} alt="" />
                  </div>
                  <div className="flex flex-col justify-between flex-1 p-6 bg-white">
                    <div className="flex-1">
                      <a href="#" className="block mt-2">
                        <p className="text-xl font-semibold text-neutral-600">{service.title}</p>
                        <p className="mt-3 text-base text-gray-500">{service.subtitle}</p>
                      </a>
                    </div>
                  </div>
                </div>
              </FadeIn>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;

