import React from 'react'
import { MessageAction } from '../data'

const Contact = () => {
  return (
    <div className="bg-pink-600 w-full">
    <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16">
      <div className="max-w-xl sm:mx-auto lg:max-w-2xl flex items-center justify-center">
        <div className="flex flex-col mb-4 sm:text-center sm:mb-0  items-center justify-center">
          <div className="max-w-xl mb-4 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 ">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto items-center justify-center">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-deep-purple-accent-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="700c93bf-0068-4e32-aafe-ef5b6a647708"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#700c93bf-0068-4e32-aafe-ef5b6a647708)"
                    width="52"
                    height="24"
                  />
                </svg>
              </span>
              comment soutenir
            </h2>
            <p className="text-base text-indigo-100 md:text-lg">
              {MessageAction}
            </p>
          </div>
          <div className='items-center justify-center mx-auto'>
            <a
              href="mailto:contact@ajeacm.com"
              className="inline-flex items-center mx-2 justify-center h-12 px-6 font-semibold tracking-wide text-teal-900 transition duration-200 rounded shadow-md hover:text-deep-purple-900 bg-teal-300 hover:bg-deep-purple-accent-100 focus:shadow-outline focus:outline-none mb-2"
              
            >
            <i className="fa-regular fa-envelope mx-2"></i>
              Email
            </a>
            <a
              href="https://wa.me/+237697491702"
              className="inline-flex items-center mx-2 justify-center h-12 px-6 font-semibold tracking-wide text-teal-900 transition duration-200 rounded shadow-md hover:text-deep-purple-900 bg-teal-300 hover:bg-deep-purple-accent-100 focus:shadow-outline focus:outline-none"
              
            >
              <i className="fa-brands fa-whatsapp mx-2"></i>
              whatsapp
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Contact
